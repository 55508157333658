import {Avatar, Button, Col, Image, Layout, Menu, Modal, Row, Space, Typography} from "antd";
import {Link, Outlet} from "react-router-dom";
import {useState} from "react";
import {MailOutlined, MenuOutlined, UserOutlined} from "@ant-design/icons";
import packageJson from '../../../../package.json';

const {Header, Content, Footer} = Layout;

const packageJsonVersion = packageJson.version;

const menuItems = [
    {
        label: 'Navigation Two',
        key: 'mail',
        icon: <MailOutlined />,
    },
    {
        label: 'Navigation Two',
        key: 'app',
        icon: <MailOutlined />,
    },
    {
        label: 'Navigation Four',
        key: 'app2',
        icon: <MailOutlined />,
    },
];

export default function OwnLayout() {

    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <Layout style={{minHeight: '1000px'}}>
            <Header>
                <Row>
                    <Col span={6}>
                        <Row justify={'start'}>
                            <Col>
                                <Link to={'/'}>
                                    <Typography.Text strong>
                                        TRIPLEY Shipping Tool
                                    </Typography.Text>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={18}>
                        <Row justify={'end'}>
                            <Col>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Header>
            <Content>
                <Outlet />
            </Content>
            <Footer>
                <Row justify={'center'}>
                    <Col>
                        <Typography.Text>
                            Trip Ship Alpha v{packageJsonVersion}
                        </Typography.Text>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Col>
                        <Typography.Text>
                            © 2024 by Zentaur.Network GmbH
                        </Typography.Text>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Col>
                        <Typography.Text>
                            Alle Rechte vorbehalten.
                        </Typography.Text>
                    </Col>
                </Row>
            </Footer>
            <Modal title="Menu" footer={null} open={isModalOpen} onCancel={()=>{setIsModalOpen(false)}}>
                <Menu mode="vertical" items={menuItems} onClick={()=>{setIsModalOpen(false)}} />
            </Modal>
        </Layout>
    );
}
