import {useEffect, useRef, useState} from "react";
import {
    Avatar,
    Button,
    Card,
    Col,
    Form,
    Image,
    Input,
    Layout,
    Row, Select,
    Space,
    Statistic,
    Table,
    Tabs, Tag,
    Typography
} from "antd";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import { PDFDocument } from 'pdf-lib';



export default function Home() {

    const [tabelData, setTabelData] = useState([]);
    const [selectedTableRows, setSelectedTableRows] = useState([]);
    const [shippingLableLoading, setShippingLableLoading] = useState(false);
    const [lableLoading, setLableLoading] = useState(false);
    const [statusFilter, setStatusFilter] = useState('all');

    const filterByProductsOptions = [];

    useEffect(() => {
        loadData();
    }, []);

    const columns = [
        {
            title: 'OrderID',
            dataIndex: 'id',
            sorter: (a, b) => a.id - b.id,
            render: (_, record) => (
                <a href={'https://tripley.com/wp-admin/post.php?post='+record.id+'&action=edit'} target={'_blank'}>#{record.id}</a>
            ),
        },
        {
            title: 'ShippingID',
            dataIndex: 'shipments',
            sorter: (a, b) => a.shipments[0].id - b.shipments[0].id,
            render: (_, record) => (
                <p>#{record.shipments[0].id}</p>
            ),
        },
        {
            title: 'Tracking',
            dataIndex: 'tracking',
            render: ((_, record) => {
                //console.log('lol: ', record.id);
                let tagColor;
                let tagText;
                let prefix = '';

                if(record.shipments[0].tracking_id == '' && record.shipments[0].status != 'delivered'){
                    tagColor = 'red';
                    tagText = 'ERROR [Address]';
                }else{
                    prefix = '#';
                    if(record.shipments[0].status == 'processing'){
                        tagText = 'Waiting for Shipping';
                        tagColor = 'orange';
                    }else if(record.shipments[0].status == 'shipped'){
                        tagText = record.shipments[0].status;
                        tagColor = 'blue';
                    }else if(record.shipments[0].status == 'delivered'){
                        tagText = record.shipments[0].status;
                        tagColor = 'green';
                    }
                }

                return (
                    <Row>
                        <Col span={24}>
                            {prefix}<a href={record.shipments[0].tracking_url} target={'_blank'}>{record.shipments[0].tracking_id}</a>
                        </Col>
                        <Col span={24}>
                            <Tag color={tagColor}>{tagText}</Tag>
                        </Col>
                    </Row>
                );
            }),
        },
        {
            title: 'ShippingData',
            dataIndex: 'shippingdata',
            render: (_, record) => (
                <Row>
                    <Col span={24}>
                        <Typography.Text>
                            Items: <b>{record.shipments[0].items.length}</b>
                        </Typography.Text>
                    </Col>
                    <Col span={24}>
                        <Typography.Text>
                            Total Weight: <b>{record.shipments[0].content_weight}{record.shipments[0].weight_unit}</b>
                        </Typography.Text>
                    </Col>
                </Row>
            ),
        },
        {
            title: 'Payment',
            dataIndex: 'payment',
            render: (_, record) => (
                <Row>
                    <Col span={24}>
                        <Typography.Text>
                            {record.payment_method_title}
                        </Typography.Text>
                    </Col>
                    <Col span={24}>
                        <Typography.Text>
                            {record.transaction_id}
                        </Typography.Text>
                    </Col>
                </Row>
            ),
        },
        {
            title: 'Address',
            dataIndex: 'address',
            render: (_, record) => (
                <Row>
                    <Col span={24}>
                        <Typography.Text>
                            {record.shipments[0].address.first_name} {record.shipments[0].address.last_name}
                        </Typography.Text>
                    </Col>
                    <Col span={24}>
                        <Typography.Text>
                            {record.shipments[0].address.address_1}
                        </Typography.Text>
                    </Col>
                    <Col span={24}>
                        <Typography.Text>
                            {record.shipments[0].address.postcode} {record.shipments[0].address.city}
                        </Typography.Text>
                    </Col>
                    <Col span={24}>
                        <Typography.Text>
                            {record.shipments[0].address.country}
                        </Typography.Text>
                    </Col>
                </Row>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: ((_, record) => {
                //console.log('lol: ', record.shipments[0]);
                return (
                    <Space direction={'vertical'}>
                        <a href={'https://tripley.com/wp-admin/admin-ajax.php?action=generate_wpo_wcpdf&document_type=packing-slip&order_ids='+record.number+'&access_key='+record.order_key} target={'_blank'}>Packing Slip</a>
                        {(record.shipments[0].tracking_id == ''
                            ?
                            <a>Label (ERROR)</a>
                            :
                            <a onClick={()=>{generateSingleLabel(record.shipments[0].id)}}>Shipping Label</a> )}
                    </Space>
                );
            }),
        },
    ];


    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedTableRows(selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.shipments[0].tracking_id === '',
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    const generateBulkShippingLables = async (pdfLinks) => {
        if (!pdfLinks || pdfLinks.length < 2) {
            alert('Bitte stellen Sie sicher, dass Sie mindestens zwei PDF-Links angegeben haben.');
            return;
        }

        const pdfDoc = await PDFDocument.create();

        for (const pdfLink of pdfLinks) {
            const pdfBytes = await fetch(pdfLink).then((res) => res.arrayBuffer());
            const existingPdfDoc = await PDFDocument.load(pdfBytes);
            const copiedPages = await pdfDoc.copyPages(existingPdfDoc, existingPdfDoc.getPageIndices());
            copiedPages.forEach((page) => {
                pdfDoc.addPage(page);
            });
        }

        const mergedPdfBytes = await pdfDoc.save();

        const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
    };

    const generateSingleLabel = async (shipmentID) => {
        let tmp = await axios.post('/api/wp/getshippinglables', {shippingID: shipmentID});
        let data = tmp.data.data.files[0].file.replace(/(\r\n|\n|\r)/gm, "").trim();
        const pdfDoc = await PDFDocument.create();
        const existingPdfDoc = await PDFDocument.load(data);
        const copiedPages = await pdfDoc.copyPages(existingPdfDoc, existingPdfDoc.getPageIndices());
        copiedPages.forEach((page) => {
            pdfDoc.addPage(page);
        });

        const mergedPdfBytes = await pdfDoc.save();

        const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
    };


    const generateBulkLables = async (pdfData) => {
        if (!pdfData || pdfData.length < 2) {
            alert('Bitte stellen Sie sicher, dass Sie mindestens zwei PDF-Links angegeben haben.');
            return;
        }

        const pdfDoc = await PDFDocument.create();

        for (const pdfLink of pdfData) {
            const existingPdfDoc = await PDFDocument.load(pdfLink);
            const copiedPages = await pdfDoc.copyPages(existingPdfDoc, existingPdfDoc.getPageIndices());
            copiedPages.forEach((page) => {
                pdfDoc.addPage(page);
            });
        }

        const mergedPdfBytes = await pdfDoc.save();

        const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
    };

    async function loadData(){
        let data = await axios.get('/api/wp/getorderdata');

        data.data.data.map(async (data)=>{

            console.log('payPapTrackingUpdate: ', data);


            if(data.payment_method_title == 'PayPal' && data.shipping_status == 'shipped'){
                let payPapTrackingState = data.meta_data.find(item => item.key === "paypal_dhl_tracking") !== undefined;

                if(!payPapTrackingState){
                    console.log('payPapTrackingUpdate: ', true);
                    await updatePayPalState(data.id, data.transaction_id, data.shipments[0].tracking_id, data.shipments[0].tracking_url);
                }
            }
        });

        setTabelData(data.data.data);
    }

    async function create_shipping_labels(){
        setShippingLableLoading(true);

        let linksArray = [];

        selectedTableRows.map((data)=>{
            linksArray.push('https://tripley.com/wp-admin/admin-ajax.php?action=generate_wpo_wcpdf&document_type=packing-slip&order_ids='+data.number+'&access_key='+data.order_key)
        });

        await generateBulkShippingLables(linksArray);

        setShippingLableLoading(false);

    }

    async function creats_labels(){
        setLableLoading(true);

        const lableArray = await Promise.all(
            selectedTableRows.map(async (data) => {

                let tmp = await axios.post('/api/wp/getshippinglables', {shippingID: data.shipments[0].id});

                //lableArray.push(tmp.data.data.files[0].file.replace(/(\r\n|\n|\r)/gm, "").trim());
                return tmp.data.data.files[0].file.replace(/(\r\n|\n|\r)/gm, "").trim();
            })
        )

        await generateBulkLables(lableArray);

        setLableLoading(false);
    }

    const filterByShippingState = (value) => {
        setStatusFilter(value);
    };

    const filterByProductsChange = (value) => {
        console.log(`selected ${value}`);
    };

    function filterTableData(){

        switch (statusFilter) {
            case 'all':
                return tabelData;
                break;
            case 'processing':
                return tabelData.filter((data) => data.shipments[0].status == 'processing');
                break;
            case 'shipped':
                return tabelData.filter((data) => data.shipments[0].status == 'shipped');
                break;
            case 'delivered':
                return tabelData.filter((data) => data.shipments[0].status == 'delivered');
                break;
            default:
                return tabelData;
        }
    }


    async function updatePayPalState(orderData, paypalOrderId, paypalTrackingNumber, dhlTrackingLink){

        await axios.post('/api/wp/setpaypaltrackingstate', {orderID: orderData, paypalOrderId: paypalOrderId, paypalTrackingNumber: paypalTrackingNumber, dhlTrackingLink: dhlTrackingLink})
    }

    return (
        <Row justify={'center'}>
            <Col span={23}>
                <Row>
                    <Col span={24} style={{paddingTop: 20, paddingBottom: 20}}>
                        <Space>
                            <Button loading={shippingLableLoading} disabled={(selectedTableRows.length<2?true:false)} onClick={()=>{create_shipping_labels()}}>
                                Bulk Packing Slip
                            </Button>

                            <Button loading={lableLoading} disabled={(selectedTableRows.length<2?true:false)} onClick={()=>{creats_labels()}}>
                                Bulk Shipping Labels
                            </Button>

                            <Select
                                defaultValue="all"
                                style={{
                                    width: 120,
                                }}
                                onChange={filterByShippingState}
                                options={[
                                    {
                                        value: 'all',
                                        label: 'All',
                                    },
                                    {
                                        value: 'processing',
                                        label: 'Waiting ',
                                    },
                                    {
                                        value: 'shipped',
                                        label: 'Shipped',
                                    },
                                    {
                                        value: 'delivered',
                                        label: 'Delivered',
                                    }
                                ]}
                            />
                            {/*
                            <Select
                                mode="multiple"
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Please select"
                                defaultValue={['All Producs']}
                                onChange={filterByProductsChange}
                                options={filterByProductsOptions}
                            />
                            */}

                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table
                            loading={(tabelData.length<1?true:false)}
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                            }}
                            rowKey={'id'}
                            columns={columns}
                            dataSource={filterTableData()}
                            pagination={{
                                defaultPageSize: 30,
                                pageSizeOptions: [30, 60, 100]
                            }}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
