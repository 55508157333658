import {Button, Col, Form, Layout, Result, Row, Typography, Input} from "antd";
import {BrowserRouter, Link, Navigate, redirect, Route, Routes, useNavigate} from "react-router-dom";
import OwnLayout from "./app/components/layout/OwnLayout";
import Home from "./app/pages/home/Home";
import {useCookies} from "react-cookie";

const {Header, Content, Footer} = Layout;

export default function App() {
    const [cookies, setCookie] = useCookies(['auth']);

    const ProtectedRoute = ({children }) => {

        if (!cookies.auth) {
            return <Navigate to="/login" replace />;
        }

        return children;
    };

    const onFinish = (values) => {

        if(values.password == 'NvsCx^CTCXeLE97TktUt*rxinBL%EJ'){
            setCookie('auth', true);
            window.location.href = "/";
        }else {
            alert('Wrong Password')
        }
    };

  return (
      <BrowserRouter>
          <Routes>
              <Route element={<OwnLayout />}>
                  <Route
                      index
                      element={
                      <ProtectedRoute>
                          <Home />
                      </ProtectedRoute>}
                  />
                  <Route
                      path="/login"
                      element={
                          <Row>
                              <Col span={24}>
                                  <Result
                                      title="Enter Login"
                                      extra={
                                          <Row justify={'center'}>
                                              <Col>
                                                  <Row>
                                                      <Col>
                                                          <Form
                                                              name="basic"
                                                              onFinish={onFinish}
                                                              autoComplete="off"
                                                          >
                                                              <Form.Item
                                                                  label="Password"
                                                                  name="password"
                                                                  rules={[
                                                                      {
                                                                          required: true,
                                                                          message: 'Please input password!',
                                                                      },
                                                                  ]}
                                                              >
                                                                  <Input.Password />
                                                              </Form.Item>

                                                              <Form.Item>
                                                                  <Button type="primary" htmlType="submit">
                                                                      Enter
                                                                  </Button>
                                                              </Form.Item>
                                                          </Form>
                                                      </Col>
                                                  </Row>
                                              </Col>
                                          </Row>
                                      }
                                  />
                              </Col>
                          </Row>
                      }
                  />
                  <Route path="*" element={<NoMatch />} />
              </Route>
          </Routes>
      </BrowserRouter>
  );
}

function NoMatch(){
    return(
        <Row>
            <Col span={24}>
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={<Link to={'/'}><Button type="primary">Back Home</Button></Link>}
                />
            </Col>
        </Row>
    );
}
