import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ConfigProvider} from "antd";
import './assets/App.css';
import {CookiesProvider} from "react-cookie";

const root = ReactDOM.createRoot(document.getElementById('root'));

function Root() {

    return (
        <ConfigProvider theme={{ token: { colorPrimary: '#00b96b' } }}>
            <CookiesProvider>
                <App />
            </CookiesProvider>
        </ConfigProvider>
    )
}

root.render(<Root/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(<Root/>);
